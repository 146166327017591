import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';

const HomePage = () => {
  useEffect(() => {
    navigate('https://www.analisedeacoes.com');
  }, []);

  return (
    <Layout page={"home"}>
      <Seo
        title="Análise de Ações"
        subtitle="Descomplicamos sua análise fundamentalista"
        description="Sua liberdade financeira começa agora! Aprenda a investir sem depender de ninguém." />
      <p>Redirecionando...</p>
    </Layout>
  );
};

export default HomePage;
